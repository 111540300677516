export const SET_ERROR_PAGE_CONTENT = "SET_ERROR_PAGE_CONTENT";
export const CLEAR_ERROR_PAGE = "CLEAR_ERROR_PAGE";

const getGraphqlErrorPageContent = (error) => {
  const { graphQLErrors } = error;

  const isPermissionError =
    graphQLErrors?.[0]?.code === "MISSING_PERMISSION_ERROR";

  return {
    title: isPermissionError ? graphQLErrors?.[0]?.data.permission_code : "",
    message: isPermissionError
      ? "Please contact an administrator"
      : graphQLErrors?.[0]?.message,
    statusCode: graphQLErrors?.[0]?.code,
  };
};

export const setErrorPageContent = ({ error }) => {
  const { networkError, message, statusCode, title } = error;
  const graphqlErrorPageContent = getGraphqlErrorPageContent(error);

  return {
    type: SET_ERROR_PAGE_CONTENT,
    message:
      message || networkError?.message || graphqlErrorPageContent?.message,
    title: title || networkError?.name || graphqlErrorPageContent?.title,
    statusCode:
      statusCode ||
      networkError?.statusCode ||
      graphqlErrorPageContent?.statusCode,
  };
};

export const clearErrorPage = () => ({ type: CLEAR_ERROR_PAGE });
