import { getServerSettings } from "@/utils/serverUtils";

export const unregisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  [...rawData].forEach(
    (data, index) => (outputArray[index] = rawData.charCodeAt(index)),
  );

  return outputArray;
};

export const getPublicVapidKey = () => {
  const { vapidPublicApplicationServerKey } = getServerSettings();
  return urlBase64ToUint8Array(vapidPublicApplicationServerKey);
};

const convertToBase64 = (key) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
};

/* TODO: Replace with let/const in the future */
const getBrowserName = (userAgent) => {
  var ua = userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return M[0].toUpperCase();
};

export const getRegistrationId = (subscription) => {
  if (!subscription) return null;

  const { endpoint } = subscription;
  const endpointParts = endpoint.split("/");
  return endpointParts[endpointParts.length - 1];
};

export const prepareCreateWebPushDeviceInput = ({ user, subscription }) => {
  return {
    active: true,
    name: `${user.firstName} ${user.lastName}`,
    browser: getBrowserName(navigator.userAgent),
    registrationId: getRegistrationId(subscription),
    p256dh: convertToBase64(subscription.getKey("p256dh")),
    auth: convertToBase64(subscription.getKey("auth")),
  };
};
