import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import * as apolloSelectors from "@/selectors/apolloSelectors";
import * as userSelectors from "@/selectors/userSelectors";

/* This will be the most verbose definition for the "me" query we have */
const GET_ME = gql`
  query GetMe {
    me {
      id
      isHidden

      user {
        email
        firstName
        lastName
      }

      roles {
        id
        permissionCategories {
          id
          roleId
          permissions {
            id
            code
            roleId
            name
            code
            isEnabled
          }
        }
      }
    }
  }
`;

const useMeQuery = ({ objectShape, queryOptions = {} } = {}) => {
  const accessToken = useSelector(userSelectors.accessToken);
  const isAuthClientReady = useSelector(apolloSelectors.isAuthClientReady);

  const QUERY = useMemo(() => {
    if (objectShape) {
      return gql`
        query GetMe {
          me ${objectShape}
        }
      `;
    }

    return GET_ME;
  }, [objectShape]);

  return useQuery(QUERY, {
    skip: !accessToken || !isAuthClientReady,
    ...queryOptions,
  });
};

export default useMeQuery;
